import React, { useEffect, useState } from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./Assets/Styles/style.css";
import sliderImg from "./Assets/images/one.jpg";
//import "./Assets/Styles/styling.scss";
import Blogs from "./components/Blogs";
import ImageSlider from "./components/ImageSlider";
import Icon from "./components/Icon";
import IconImageSlider from "./components/IconImageSlider";
import PharmaCategory from "./components/pharmaCategory";
import InputForm from "./components/InputForm";
import About from "./pages/About";
import CategoriesList from "./Layouts/CategoriesList";
import { FcExternal } from "react-icons/fc";
import CategoriesSlider from "./components/CategoriesSlider";
import CategoriesHook from "./Hook/CategoriesHook";
import Modal from "./components/Modal";
import { useParams } from "react-router-dom";

// import Modal from 'react-modal';
// import "./Assets/Styles/mystyle.css";

// Modal.setAppElement('#yourAppElement');

const App = () => {
  const [showScrollToTopButton, setShowScrollToTopButton] = useState(false);
  const [categoryAll, setCategoryAll] = useState(false);
  const [show, setShow] = useState(true);
  const [modalForm, setModalFrom] = useState(false);
  // const [mobileScreen,setMobileScreen]=useState()

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    window.addEventListener("scroll", handleScrollPosition);
    handleScrollPosition();
    return () => {
      window.removeEventListener("scroll", handleScrollPosition);
    };
  }, []);

  const handleScrollPosition = () => {
    const scrollY = window.scrollY;
    setShowScrollToTopButton(scrollY > 400);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  const handleCategory = () => {
    setCategoryAll(true);
    setShow(false);
  };

  return (
    <>
      {/* <Navbar /> */}
      <div className="pstn position-relative">
        {/* {categoryAll && <CategoriesListAll setCategoryAll={setCategoryAll} />} */}

        <div className="row g-0">
          <CategoriesList handleCategory={handleCategory} show={show} />
          {/* <div className="col-lg-3 col-md-3 category1 ">
            
          </div> */}
          {/* <div className="row category1 category2">
            <div className="col-4">
          <CategoriesHook/>
            </div>
           
          </div> */}
          <div className="col-lg-9 col-md-9 col-sm-12 ">
            <ImageSlider />
          </div>
        </div>
      </div>

      <Icon />
      <PharmaCategory />
      <IconImageSlider />
      <About />
      {/* <Blogs /> */}

      <section id="contact" className="bg-light">
        <div className="container py-lg-5 ">
          <div className="row mx-3 g-0 ">
            <div className="col-lg-9 mx-lg-auto">
              <h1
                className="text-center pb-2 pb-md-4"
                style={{ fontWeight: "600", fontSize: 35 }}
              >
                Send Enquiry
              </h1>
              <InputForm />
            </div>
          </div>
        </div>
      </section>

      {showScrollToTopButton && (
        <button
          onClick={scrollToTop}
          style={{
            position: "fixed",
            fontSize: "20px",
            bottom: "40px",
            right: "40px",
            backgroundColor: "#0C9",
            border: "2px",
            borderRadius: "5%",
            textAlign: "center",
            width: "40px",
            height: "35px",
          }}
        >
          <FcExternal size={25} />
        </button>
      )}
    </>
  );
};

export default App;
