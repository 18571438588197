import axios from "axios";
import React, { useEffect, useState } from "react";
import { setCat } from "../Redux/Action";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { FadeLoader } from "react-spinners";
import { FaHandPointRight } from "react-icons/fa";

const CategoriesSlider1 = ({data,loading,handleClick}) => {
  //console.log(data,'=======================>props');
  return (
    <>
      <div className="aside">
        <div className="bg-img2">
          <ul className="list-group rounded-0">
            <>
              {data.slice(6,15).map((item, index) => (
                <Link to="/CategoriesListPage" key={index}>
                  <li
                    className={
                      "list-group-item" 
                    //   +(active === item.category ? " list-group-item-dark" : "")
                    }
                    onClick={() => handleClick(item.category)}
                  >
                    <span className="pe-2">
                      <FaHandPointRight />{" "}
                    </span>
                    {item.category}
                  </li>
                </Link>
              ))}
            </>

            {loading && (
              <li className="list-group-item d-flex justify-content-center">
                <FadeLoader color={"#36D7B7"} loading={loading} size={15} />
              </li>
            )}
          </ul>
        </div>
      </div>
    </>
  );
};
const CategoriesSlider2 = ({data,loading,handleClick}) => {
  // console.log(data,'=======================>props');
  return (
    <>
      <div className="aside">
        <div className="bg-img2">
          <ul className="list-group rounded-0">
            <>
              {data.slice(15,).map((item, index) => (
                <Link to="/CategoriesListPage" key={index}>
                  <li
                    className={
                      "list-group-item" 
                    //   +(active === item.category ? " list-group-item-dark" : "")
                    }
                    onClick={() => handleClick(item.category)}
                  >
                    <span className="pe-2">
                      <FaHandPointRight />{" "}
                    </span>
                    {item.category}
                  </li>
                </Link>
              ))}
            </>

            {loading && (
              <li className="list-group-item d-flex justify-content-center">
                <FadeLoader color={"#36D7B7"} loading={loading} size={15} />
              </li>
            )}
          </ul>
        </div>
      </div>
    </>
  );
};

export  {CategoriesSlider1,CategoriesSlider2}
