import React, { useEffect, useState } from "react";
import { ImLocation2 } from "react-icons/im";
import { BiPhoneCall } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { SetCompanyName } from "../Redux/Action";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import LoaderDiv from "../components/LoaderDiv";
import Head from "../Layouts/Head";
import { LazyLoadImage } from "react-lazy-load-image-component";

const DivisonDetails = ({ position }) => {
  const [companyDetails, setCompanyDetails] = useState();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    window.scrollTo(0, position);
  }, [position]);

  const dispatch = useDispatch();
  const{id}= useParams();
  // console.log(Params,"hdhdhdkj")
  // const id = Params.id;

  useEffect(() => {
    getDataApi();
  }, [id]);

  const getDataApi = async () => {
    try {
      const res = await axios.get(
        `https://pharmapcdfranchise.co.in/medicine_admin/api/getlistbyId/${id}`
      );
      setCompanyDetails(res.data);
      //console.log(res.data, "============pp");
      setIsLoading(false);
    } catch (error) {}
  };
  const handleCompanyName = (item) => {

    dispatch(SetCompanyName(item));
  };

  if (isLoading) {
    return <LoaderDiv />;
  }
  return (
    <>
      <Head title={companyDetails?.title} description={companyDetails?.title} />
      <div className="company_bg-detail-55">
        <div className="container mb-5">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 my-3 my-sm-5 d-flex align-items-center">
              <LazyLoadImage
                className="details_logo_1 img"
                src={companyDetails?.image}
                alt="img"
              />
            </div>

            <div className="col-lg-6 col-md-12 col-sm-12 my-3 my-sm-5 ps-4">
              <h1 className="company-head_main_abc">{companyDetails?.title}</h1>

              <p className="something-about-company">
                {" "}
                {companyDetails?.description_front}{" "}
              </p>

              <div className="" style={{display:'flex', fontSize:15, fontWeight:'500', color:'#000000'}} >
                <ImLocation2 color="#0f0b59" size={20} />

                <div
                  className="st-post-text mt-1"
                  dangerouslySetInnerHTML={{
                    __html: companyDetails?.address,
                  }}
                />
              </div>

              <p className="address_comp_1  fw-normal">
                <span>
                  <BiPhoneCall />
                </span>{" "}
                {companyDetails?.contact}
              </p>

              <Link
                to={companyDetails?.url}
                target="_blank"
                className="comp-detail_54"
              >
                Profile
              </Link>

              <button
                type="button"
                className="comp-detail_54"
                onClick={() => handleCompanyName(companyDetails?.title)}
                data-bs-toggle="modal"
                data-bs-target="#exampleModals"
              >
                Send Enquiry
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DivisonDetails;
