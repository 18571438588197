import React, { useEffect, useState } from "react";
import image from "../Assets/images/one.jpg";
import { useParams } from "react-router-dom";
import axios from "axios";
import { parseISO, format } from "date-fns";
import Loader from "../components/Loader";
import Footer from "../Layouts/Footer";
import { FadeLoader, GridLoader } from "react-spinners";
import LoaderBlog from "../components/LoaderBlog";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
// import { Helmet } from "react-helmet";
import Head from "../Layouts/Head";

const BlogView = ({ position }) => {
  useEffect(() => {
    window.scrollTo(0, position);
  }, [position]);
  const obj = useParams();
 // console.log(obj, "blog id");
  const idValue = obj.blog;
  // const Params = useParams();
  // const id = Params.id;
  //console.log(idValue, "======");
  //console.log(idValue);
  const [data, setData] = useState("");
  //console.log(data,'=====================');
  
  const [loading, setLoading] = useState(false);
  const [isDate, setIsDate] = useState();
  const [showTopBtn, setShowTopBtn] = useState(false); // Step 1: State variable to manage the scroll-to-top button
  const [blogData, setBlogData] = useState([]);
  //console.log(data.title, "hello");

  useEffect(() => {
    setLoading(true);
    getApiData();
  }, [obj, idValue]);

  const getApiData = async () => {
    try {
      const res = await axios.get(
        `https://pharmapcdfranchise.co.in/medicine_admin/api/allblogs/${idValue}`
      );

      const resAll = await axios.get(
        "https://pharmapcdfranchise.co.in/medicine_admin/api/allblogs"
      );
      //console.log(res.data, "lloooooo");
      const jsonData = resAll.data;

      // Remove the object with matching id from jsonData
      const filteredData = jsonData.filter((item) => item.slug != idValue);

      // console.log("idValue:", idValue);
      //console.log(filteredData, "filtered data");

      setData(res.data);
      //console.log(res.data, "vnfviof");
      setBlogData(filteredData);
      const dateObject = parseISO(res?.data?.CreatedAt);
      const formattedDate = format(dateObject, "MMM dd, yyyy");
      setIsDate(formattedDate);
      setLoading(false);
    } catch (error) {
      setLoading(true);
    }
  };

  if (loading) {
    return <LoaderBlog />;
  }
  return (
    <>
      {/* <Helmet>
        <title>{data?.meta_title}</title>
        <meta
          name="description"
          content={data?.meta_description}
        />
        <meta
          name="keywords"
          content={data?.tag}
        />
      </Helmet> */}
      <Head title={data?.meta_title} description={data?.meta_description} />
      <div className="blogparent3">
        <div className="px-5 py-5">
          <div className="row">
            <div className="col-lg-9 col-md-7 col-sm-12">
              <div className="col-12 mx-auto">
                <div className="d-flex justify-content-center ">
                  <LazyLoadImage
                    src={data?.image}
                    className="img-fluid"
                    alt="..."
                  />
                </div>
                <div className="col-12">
                  <div className="d-flex justify-content-between pt-5">
                    {/* <div className="fw-bolder">Categories {data?.category}</div> */}
                    <div className="fw-bolder st-post-date">
                      <span>{isDate}</span>
                    </div>
                  </div>
                  <h1
                    className="header-align fw-bolder py-3"
                    style={{ fontSize: "22px" }}
                  >
                    {data?.title}
                  </h1>
                  <p className="blog_desc_1">
                    {/* Check if data.description is available before rendering */}
                    {data?.description ? (
                      <span
                        dangerouslySetInnerHTML={{ __html: data.description }}
                      />
                    ) : (
                      "No description available"
                    )}
                  </p>
                  {/* <p className="pb-4">
                  {data?.description_front}</p> */}
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-5 col-sm-12 mt-3">
              {blogData.slice(0, 5).map((item, index) => {
                const dateObject = parseISO(item.CreatedAt);
                const formattedDate = format(dateObject, "MMM dd, yyyy");
                return (
                  <Link
                    to={`/${item.slug}/`}
                    className="blog_container"
                    key={index}
                  >
                    <div className="image_container_left">
                      <img src={item.image} width={105} height={105} />
                    </div>

                    <div className="text_container_right">
                      <h6 className="blog_date_top st-post-date">
                        {formattedDate}
                      </h6>
                      <Link
                        to={`/${item.slug}/`}
                        style={{ color: "grey" }}
                      >
                        <h5 className="fw-normal">{item.title}</h5>
                      </Link>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item?.description_front.slice(0, 25) + "...",
                        }}
                      />
                      {/* <h5 className="blog_desc_55">
                        {item.description_front.slice(0, 70)}...
                      </h5> */}
                    </div>
                  </Link>
                );
              })}
            </div>
            {/* <div className="col-12 d-none d-md-block">
              <div className="d-flex justify-content-between pt-5">
                <div className="fw-bolder">
                  Date <span>{isDate}</span>
                </div>
              </div>
              <h1 className="header-align fw-bolder py-3">{data?.title}</h1>
              <p className="pb-4">{data?.description_front}</p>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogView;
