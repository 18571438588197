import React,{useEffect} from 'react'
import { Navigate } from 'react-router-dom';

const ErrorPage = () => {
    // useEffect(() => {
    //     const errorPage = document.querySelector('.error-page');
    //     errorPage.classList.add('show');
    //   }, []);
  return (
    <>
         {/* <div className="error-page">
    <div className="container">
      <div className="error-icon">⚠️</div>
      <h1>404</h1>
      <h3>UH OH! You're lost.</h3>
      <h6 className='error-center'>
        The page you are looking for does not exist. How you got here is a mystery. But you can click the button
        below to go back to the homepage.
      </h6>
      <Link to="/">Home</Link>
    </div>
  </div> */}
    <Navigate to="/" />
    </>
  )
}

export default ErrorPage