import axios from "axios";
import React, { useEffect, useState } from "react";
import { setCat } from "../Redux/Action";
import { useDispatch } from "react-redux";
// import { Link, useNavigate } from "react-router-dom";
import { FadeLoader } from "react-spinners";
import { FaHandPointRight, FaChevronDown, FaChevronUp } from "react-icons/fa";
import { RxCross1 } from "react-icons/rx";
import { Link } from "react-router-dom";
//import { Link, Element, animateScroll as scroll } from "react-scroll";

const CategoriesList = ({ position }) => {
  const dispatch = useDispatch();
  const [isCategoryVisible, setIsCategoryVisible] = useState(false);
  //console.log(isCategoryVisible, 'mobile screen');
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  // console.log(data,'======data');
  useEffect(() => {
    setLoading(true);
    getApiData();

    // Check the initial screen width and set visibility accordingly
    setIsCategoryVisible(window.innerWidth >= 768);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsCategoryVisible(window.innerWidth >= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getApiData = async () => {
    try {
      const res = await axios.get(
        "https://pharmapcdfranchise.co.in/medicine_admin/api/categorybyidss"
      );
      const shuffledData = [...res.data];
      //console.log(shuffledData, res.data, "siffgiafnkafbaknskdfnkD");
      for (let i = shuffledData.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledData[i], shuffledData[j]] = [shuffledData[j], shuffledData[i]];
      }
      setData(shuffledData);
      //setData(res.data);
      setLoading(false);
    } catch (error) {
      setLoading(true);
    }
  };
  useEffect(() => {}, []);

  const toggleCategoryVisibility = () => {
    setIsCategoryVisible(!isCategoryVisible);
    // console.log(isCategoryVisible, "jjjj")
  };
  // function superEncodeURI(url) {
  //   var encodedStr = '', encodeChars = ["(", ")", "/"];
  //   url = decodeURIComponent(decodeURIComponent(url));

  //   for(var i = 0, len = url.length; i < len; i++) {
  //     if (encodeChars.indexOf(url[i]) >= 0) {
  //         var hex = url.charCodeAt(i).toString(16);
  //         encodedStr += '%' + hex;
  //     }
  //     else {
  //         encodedStr += url[i];
  //     }
  //   }

  //   return encodedStr;
  // }

  const handleClick = (item) => {
    dispatch(setCat(item));
    //console.log(item);
    // setActive(item);
    // setCategoryAll(false);
  };

  return (
    <div className={"col-lg-3 col-md-3 category1"}>
      <div className="aside">
        <div className="bg-img2">
          <ul className="list-group rounded-0">
            {/* <li
              className={`category-head fw-bolder fs-3 categories-padding d-flex justify-content-between ${
                isCategoryVisible ? "active" : ""
              }`}
              onClick={toggleCategoryVisibility}
            >
              Categories
              {isCategoryVisible && (
                <span>
                  {isCategoryVisible ? <FaChevronUp /> : <FaChevronDown />}
                </span>
              )}
            </li> */}
            <li
              className={`category-head fw-bolder fs-3 categories-padding d-flex justify-content-between active`}
              onClick={toggleCategoryVisibility}
            >
              Categories
              {window.innerWidth < 768 && (
                <span>
                  {isCategoryVisible ? <FaChevronUp /> : <FaChevronDown />}
                </span>
              )}
            </li>

            {(isCategoryVisible || window.innerWidth >= 768) && !loading && (
              <>
                {data.length === 0 ? (
                  <li className="list-group-item d-flex justify-content-center">
                    No Categories found
                  </li>
                ) : (
                  data.map((item, index) => {
                    // console.log(item.category_slug, item.category, 'guriii')

                    // const categorySlugEncoded = superEncodeURI(item.category_slug);
                    const categorySlugEncoded = item?.category_slug
                      .replace(/\(/g, "%28")
                      .replace(/\)/g, "%29")
                      .replace(/\//g, "%252F");
                    // console.log(categorySlugEncoded.toLowerCase(), "lll");
                    return (
                      <Link
                        key={index}
                        to={`/${categorySlugEncoded.toLowerCase()}/*`}
                        onClick={toggleCategoryVisibility}
                      >
                        <li
                          className={`list-group-item ${
                            isCategoryVisible &&
                            isCategoryVisible === item.category
                              ? "list-group-item-dark"
                              : ""
                          }`}
                          onClick={() => handleClick(item.category)}
                        >
                          <span className="pe-2">
                            <FaHandPointRight />{" "}
                          </span>
                          {item.category}
                        </li>
                      </Link>
                    );
                  })
                )}
              </>
            )}

            {loading && (
              <li className="list-group-item d-flex justify-content-center">
                <FadeLoader color={"#36D7B7"} loading={loading} size={15} />
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};
export default CategoriesList;
