import React, { useState } from "react";
import {
  BiLogoFacebook,
  BiLogoTwitter,
  BiLogoLinkedinSquare,
  BiLogoYoutube,
  BiLogoGmail,
} from "react-icons/bi";
import { Link } from "react-router-dom";
import { Divider } from "rsuite";
const Header = () => {
  const [showBar, setShowBar] = useState(false);
  // const handleShowBar = () => {
  //   if (showBar) {
  //     setShowBar(false);
  //   } else {
  //     setShowBar(true);
  //   }
  // };
  return (
    <div>
      <header className="template-header sticky-header">
        <div className="header-topbar d-none d-md-block">
          <div className="container-fluid container-1400 ">
            <div className="row align-items-center justify-content-between">
              <div className="col-lg-8">
                <ul className="topbar-menu">
                  {/* <li>
                    <a href="/">Pricing</a>
                  </li> */}
                  <li>
                    <a href="#">
                      <i className="far fa-envelope" />
                      <BiLogoGmail size={20} className="me-2" />
                      getpharmafranchise@gmail.com
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-4">
                <ul className="contact-info">
                  <li>
                    <div className="social-icons">
                      <a href="#">
                        <i className="fab fa-facebook-f icon-color">
                          <BiLogoFacebook size={25} />
                        </i>
                      </a>
                      <Divider vertical />
                      <a href="#">
                        <i className="fab fa-twitter icon-color">
                          <BiLogoTwitter size={25} />
                        </i>
                      </a>
                      <Divider vertical />
                      <a href="#">
                        <i className="fab fa-youtube icon-color">
                          <BiLogoYoutube size={25} />
                        </i>
                      </a>
                      <Divider vertical />
                      <a href="#">
                        <i className="fab fa-linkedin-in icon-color">
                          <BiLogoLinkedinSquare size={24} />
                        </i>
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* End Off Canvas */}
        {/* Start Mobile Panel */}

        {/* Start Mobile Panel */}
      </header>
    </div>
  );
};

export default Header;
