import React from "react";

function PrivacyPolicy() {
  return (
    <div className="container">
      <h5 className="py-3 ">Privacy Policy </h5>
      <h6 className="fw-bolder">
        Given below are the privacy policies of pharmapcdfranchise.co.in Users
        are requested to read it carefully to completely understand it. It is
        applicable to pharmapcdfranchise only and no other company.
      </h6>
      <h6 className="fs-6 fw-bolder pt-3">Information Collected</h6>
      <p className="fw-normal">
        Before registering, it is required to give both your personal and
        business information so that you are no more anonymous to us. It may
        include name, business profile and other important information. This
        will also help us to promote your business better as well. The
        information given to us is shared on the website for better promotions.
      </p>
      <h6 className="fs-6 fw-bolder pt-3">
        {" "}
        Utility of Personal and Business Information
      </h6>
      <p className="fw-normal">
        The main purpose of collecting personal and business information is to
        give our revered customers customized services. This will enable better
        communication between us and ensure maximum business benefits. Most of
        the services are free while some of them are paid. With our extensive
        promotions throughout our website, users start receiving various kinds
        of mails, phone calls and SMS notifications from assorted buyers across
        India. We do keep records of users buying and browsing activities on the
        site.
      </p>
      <h6 className="fs-6 fw-bolder pt-3">Sharing Information</h6>
      <p className="fw-normal">
        Though, it is generally avoidable to disclose any personal information
        to the customers. However, under special circumstances this can be done
        if pharmapcdfranchise has permission from the user to do the same. Email
        address is not displayed by us. Account information can be given if
        company feels that someone is causing damage either intentionally or
        unintentionally. Sometimes in order to maintain services and improve
        standards information can be provided. In case of sale, personal
        information can be transferred.
      </p>
      <h6 className="fs-6 fw-bolder pt-3">Circulation of Information</h6>
      <p className=" fw-normal">
        pharmapcdfranchise sends all kinds of emails to the users giving details
        about assorted products and services provided. It gives a unique
        platform to the users for providing better business opportunities
        suitable according to personal preferences. With our marketing tools it
        is smooth to make contacts for enhanced business. pharmapcdfranchise has
        right to send mails regarding products to the users who have shown
        interest in the product. Registered users are free to control
        information with their administration panel.
      </p>
      <h6 className="fs-6 fw-bolder pt-3">Update of Information</h6>
      <p className="fw-normal">
        One can edit his/her account by logging into his account and also by
        sending us the request. The up gradation of the information may take
        some time because of verification and other policies. User can also
        delete his/her account. However, other information provided will be put
        on our archive record.
      </p>
      <h6 className="fs-6 fw-bolder pt-3">Safety of information</h6>
      <p className="fw-normal">
        With our policy, we do not sell our data to the third party which can be
        misused by others. We do not sell users personal information and
        business information to other unless permitted to do so by the user. All
        the contact sharing information is provided online on website. It is
        necessary to log out of the account and close the window so that it is
        not misused. Although, we take all the steps to ensure that there is no
        leakage of information provided to us, but data transmission on the
        internet is not 100% safe. Information both personal and business is
        open to public so give them with caution.
      </p>{" "}
      <h6 className="fs-6 fw-bolder pt-3">
        Google and the Double Click DART cookie
      </h6>
      <p className="fw-normal">
        Google provides various ads on our site with its cookies. Google’s DART
        cookie provides ads to the users based on their preference and visit. It
        is possible to opt out of DART cookie through Google ad and content
        network privacy policy.
      </p>{" "}
      <h6 className="fs-6 fw-bolder pt-3">Variation In Privacy Policy</h6>
      <p className="fw-normal">
        There are regular changes in the policies of the company, hence it is
        necessary to check the website for update on privacy policies on a
        regular basis.
      </p>
      <h6 className="fs-6 fw-bolder pt-3">Copyright Compliance</h6>
      <p className="fw-normal">
        There are regular changes in the policies of the company, hence it is
        necessary to check the website for update on privacy policies on a
        regular basis.
      </p>
      <h6 className="fs-6 fw-bolder pt-3">Content Policies</h6>
      <p className="fw-normal">
        There are regular changes in the policies of the company, hence it is
        necessary to check the website for update on privacy policies on a
        regular basis.
      </p>
      <h6 className="fs-6 fw-bolder pt-3">Accuracy and Representation</h6>
      <p className="fw-normal">
        We certify that the images accurately represent the products being sold
        or promoted in our mobile application, without any misleading or
        deceptive elements.
      </p>
      <h6 className="fs-6 fw-bolder pt-3">User Experience</h6>
      <p className="fw-normal">
        We commit to optimizing the images for display on mobile devices,
        ensuring fast loading times and optimal user experience.
      </p>
      <h6 className="fs-6 fw-bolder pt-3">Types of Images Collected</h6>
      <p className="fw-normal">
        Our application collects various types of images, including product
        images, company logos, and user profile pictures.
      </p>
      <h6 className="fs-6 fw-bolder pt-3">Collection of Image Data</h6>
      <p className="fw-normal">
        Image data is collected through user uploads, supplier submissions, and
        our own image acquisition methods.
      </p>
      <h6 className="fs-6 fw-bolder pt-3">Purpose of Image Data Collection</h6>
      <p className="fw-normal">
        We collect image data to facilitate product listings, enhance user
        experience, and verify the authenticity and quality of products and
        suppliers.
      </p>
      c<h6 className="fs-6 fw-bolder pt-3">Use of Image Data</h6>
      <p className="fw-normal">
        Image data is used to display product listings, improve search and
        navigation features, and personalize the user interface for registered
        users.
      </p>
      <h6 className="fs-6 fw-bolder pt-3">Sharing of Image Data</h6>
      <p className="fw-normal">
        We may share image data with authorized third parties, such as
        suppliers, distributors, and service providers, to facilitate
        transactions, verify product authenticity, and enhance platform
        functionality. However, we do not sell or rent image data to third
        parties for marketing purposes.
      </p>
      <h6 className="fs-6 fw-bolder pt-3">User Rights and Choices</h6>
      <p className="fw-normal">
        Users have the right to upload, delete, or manage the visibility of
        their images on our platform. They can update their image settings
        through their account dashboard or by contacting our customer support
        team.
      </p>
      <h6 className="fs-6 fw-bolder pt-3">Data Security Measures</h6>
      <p className="fw-normal">
        We employ industry-standard security measures to protect image data from
        unauthorized access, disclosure, alteration, or destruction. This
        includes encryption, access controls, regular security audits, and
        employee training on data protection best practices.
      </p>
      <h6 className="fs-6 fw-bolder pt-3">Retention of Image data</h6>
      <p className="fw-normal">
        We retain image data for as long as necessary to fulfill the purposes
        outlined in this privacy policy or as required by law. Users can request
        the deletion of their image data by contacting us.
      </p>
      <h6 className="fs-6 fw-bolder pt-3">Updates to the Privacy Policy</h6>
      <p className="fw-normal">
        We may update this privacy policy periodically to reflect changes in our
        data practices or legal requirements. Any updates will be communicated
        to users through our platform or other appropriate channels.
      </p>
      <h6 className="fs-6 fw-bolder pt-3">Contact Information</h6>
      <p className="fw-normal">
        If you have any questions, concerns, or requests regarding the privacy
        of your image data, please contact us at getpharmafranchise@gmail.com
      </p>
    </div>
  );
}

export default PrivacyPolicy;
