import React from 'react'
import {
    BsChat,
    BsHandThumbsUp,
    BsShieldShaded,
    BsRocketTakeoff,
  } from "react-icons/bs";
const Icon = () => {
  return (
    <>
    <div className="container">
        <div className="row text-center my-3">
        <h1 className="owrks-padding pt-3 pb-2">How it works?</h1>
          <div className="col-md-3 col-6 mt-4">
            <span
              className="icon_circular_main rounded-circle d-block">
              <BsChat size={40} color="#fffF" />{" "}
            </span>
            <p className="fw-bolder">Post your <br /> requirements</p>
          </div>
          <div className="col-md-3 col-6 mt-4">
            <span className="icon_circular_main rounded-circle d-block">
              <BsShieldShaded
                size={40}
                color="#fffF"
              />
            </span>
            <p className="fw-bolder">Your enquiry <br /> is verified</p>
          </div>
          <div className="col-md-3 col-6 mt-4">
            <span className="icon_circular_main rounded-circle d-block">
              {" "}
              <BsRocketTakeoff
                size={40}
                color="#fffF"
              />
            </span>
            <p className="fw-bolder">Your requirements are <br /> sent to right suppliers</p>
          </div>
          <div className="col-md-3 col-6 mt-4">
            <span className="icon_circular_main rounded-circle d-block">
              <BsHandThumbsUp
                size={40}
                color="#fffF"
              />
            </span>
            <p className="fw-bolder">Suppliers will <br /> contact you</p>
          </div>
        </div>
        </div>
    </>
  )
}

export default Icon
