import axios from "axios";
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FadeLoader } from 'react-spinners';
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
const IconImageSlider = () => {
  const [data, setData] = useState([]);
  const[loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    getApiData();
  }, []);

  const getApiData = async () => {
    try {
      const res = await axios.get('https://pharmapcdfranchise.co.in/medicine_admin/api/allData');
      setData(res.data);
      setLoading(false)
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(true)
    }
  };

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="container-fluid img-slider" id="img-slider1">
    <h1 className="text-center" style={{fontSize: '20px'}}>Featured Pharma Franchise Companies</h1>
{
  loading ? 
    (
      <div className="loader2 d-flex justify-content-center"><FadeLoader className=""/></div>
    ) :(
      <div className="row slider-padding">
      {data.length === 0 ? (

        <p>Loading...</p>
      ) : (
        <Slider {...settings}>
          {data.map((item, index) => (
            <div key={index} className="">
            <Link to={item.url}>  <LazyLoadImage loading="lazy" src={item.image} alt={`Image ${index + 1}`}   /></Link>
            
            </div>
          ))}
        </Slider>
      )}
    </div>
    )
  
}

   
    </div>
  );
};

export default IconImageSlider;
