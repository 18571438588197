import React from "react";
import SendEnquiry from "./SendEnquiry";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { FaPhoneAlt } from "react-icons/fa";
const PharmaCard = ({ data, handleUrl, handleCompanyName }) => {
  return (
    <>
      <div className="cardparent">
        <div className="container">
          <div className="row justify-content-center">
            {data?.map((item, index) => {
              //console.log(item, "lllll")
              return (
                <div
                  className="col-lg-4  col-md-6 mt-3 mb-3 d-flex justify-content-center"
                  key={index}
                >
                  <div className="row">
                    <div className="col-lg-12 col-md-12">
                      <div className="features-product-content">
                        <Link
                        // to={`/${item.slug}`}
                        >
                          <div className="main-img">
                            <LazyLoadImage src={item.image} alt="img" />
                          </div>
                          <h6
                            className="title text-center   py-3"
                            style={{ color: "grey" }}
                          >
                            {item.title > 0 ? (
                              <span>{item.title}</span>
                            ) : (
                              <span>{item.company}</span>
                            )}
                          </h6>
                        </Link>
                        {/* <p className="card_content_2">
                        {item.description_front.slice(0, 30)}
                        <span>...</span>
                      </p> */}
                        {/* <div className="text-center py-2 fs-6 fw-card">
                        {item.address.slice(0, 30)}
                      </div> */}
                        {/* <div className="text-center fw-normal fw-card">
                        <span>
                          <FaPhoneAlt size={15} />
                        </span>{" "}
                        {item.contact}
                      </div> */}

                        <div className="features-product-bottom">
                          {/* <Link to={item.url}

                            className="st-btn st-style1 st-size-large st-color1"
                          >
                            Profile
                          </Link> */}

                          {/* <a
                        href="#"
                        className="st-btn st-style1 st-size-large st-color1"
                      >
                        Send Enquiry
                      </a> */}
                          <Link
                            to={item.url}
                            // to={`/divisions/${item.url}`}
                            // onClick={() => handleCompanyName(item.title)}
                            type="button"
                            className="st-btn st-style1 st-size-large st-color1"
                            // data-bs-toggle="modal"
                            // data-bs-target="#exampleModals"
                          >
                            Details
                          </Link>

                          <button
                            onClick={() => handleCompanyName(item.company)}
                            type="button"
                            className="st-btn st-style1 st-size-large st-color1"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModals"
                          >
                            Enquiry
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          {/* {data?.length > 6 && (
            <>
              <div className="button-align-center py-4">
                <Link to={''}
                  className="st-btn st-style1 st-size-large st-color1 border-0   "
                >
                  View All
                </Link>
              </div>
            </>
          )} */}
        </div>
      </div>
    </>
  );
};

export default PharmaCard;
