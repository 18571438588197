import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../Layouts/Navbar";
import Footer from "../Layouts/Footer";
import Header from "../Layouts/Header";
import { Modal, Button, ButtonToolbar, Placeholder, Input } from "rsuite";

import { useEffect } from "react";
import InputForm from "../components/InputForm";
import SEO from "./Seo";
const Root = () => {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  useEffect(() => {
    setTimeout(() => {
      setOpen(true);
    }, 2000);
  }, []);
  return (
    <>
      <SEO
        title="pharma pcd franchise | pcd pharma | call now!"
        description="Are you looking for Top Quality Pharma PCD Franchise Company in India, We provides you list of Top Pharma PCD Franchise Company in India."
        name="pharma pcd franchise | pcd pharma | call now!"
        type="article"
      />
      <>
        {/* <ButtonToolbar>
          <Button onClick={handleOpen}> Open</Button>
        </ButtonToolbar> */}

        <Modal open={open} onClose={handleClose} size="sm">
          <Modal.Header>
            <Modal.Title className="text-dark px-3 fw-bold text-center">
              SEND YOUR ENQUIRY
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="px-3">
            {/* <Placeholder.Paragraph /> */}

            <InputForm />
          </Modal.Body>
          {/* <Modal.Footer>
            <Button onClick={handleClose} appearance="primary">
              Ok
            </Button>
            <Button onClick={handleClose} appearance="subtle">
              Cancel
            </Button>
          </Modal.Footer> */}
        </Modal>
      </>
      <Header />
      <Navbar />
      <Outlet />
      <Footer />
    </>
  );
};

export default Root;
