// import React, { useEffect, useState } from "react";
// import sliderImage from "../Assets/images/one.jpg";
// import { img1, img2, img3 } from "../Assets/images/Image";
// import { LazyLoadImage } from 'react-lazy-load-image-component';
// import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
// import { Carousel } from "rsuite";
// import axios from "axios";
// import { Link } from "react-router-dom";

// const shuffleArray = (array) => {
//   const shuffledArray = [...array];
//   for (let i = shuffledArray.length - 1; i > 0; i--) {
//     const j = Math.floor(Math.random() * (i + 1));
//     [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
//   }
//   return shuffledArray;
// };
// const ImageSlider = () => {
//   const [isImage, setImage] = useState([]);
//   //console.log(isImage,'image  -----');


//   useEffect(() => {
//     getDataApi();
//   }, []);

//   const getDataApi = async () => {
//     try {
//       const res = await axios.get(
//         "https://pharmapcdfranchise.co.in/medicine_admin/api/allData"
//       );
//       //console.log(res.data);
//       const shuffledData = shuffleArray(res.data); // Shuffle the data here
//       setImage(shuffledData);
//     } catch (error) { }
//   };

//   const handleUrl = (url) => {
//     //console.log(url, "url click-=========");
//     window.open(url);
//   };


//   return (
//     <>
//       <Carousel className="custom-slider">
//         <div className="carousel_item_45"
//         // style={{ backgroundImage: `url(${img2})` }}
//         >
//           <LazyLoadImage className="imhs" src={img2} alt="" />

//         </div>
//         <div className="carousel_item_45">
//           <div className="slid-img-logos container">
//             <div className="slid-img1 row row-cols-3">
//               {
//                 isImage?.slice(0, 15).map((item, index) => {
//                   return (
//                     <LazyLoadImage
//                       key={index}
//                       src={item.image}
//                       alt="Logo1"
//                       className="col"
//                       onClick={() => handleUrl(item.url)}
//                     />
//                   )
//                 })
//               }
//             </div>
//           </div>

//           {/* <h4 className="text_overlay">
//             Lorem Ipsum is simply dummy text of the printing and typesetting
//             industry.{" "}
//           </h4> */}
//         </div>
//         <div className="carousel_item_45"
//           style={{ backgroundImage: `url(${img3})` }}
//         >
//           <img className="slider_image_main" alt="" />
//           {/* <h4 className="text_overlay">
//             Lorem Ipsum is simply dummy text of the printing and typesetting
//             industry.{" "}
//           </h4> */}
//         </div>
//       </Carousel>
//     </>
//   );
// };

// export default ImageSlider;
import React, { useEffect, useState } from "react";
import { Carousel } from "rsuite";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import axios from "axios";
import { img1, img2, img3 } from "../Assets/images/Image";


const shuffleArray = (array) => {
  const shuffledArray = [...array];
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
};

const ImageSlider = () => {
  const [isImage, setImage] = useState([]);

  useEffect(() => {
    getDataApi();
  }, []);

  const getDataApi = async () => {
    try {
      const res = await axios.get(
        "https://pharmapcdfranchise.co.in/medicine_admin/api/allData"
      );
      const shuffledData = shuffleArray(res.data);
      setImage(shuffledData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleUrl = (url) => {
    window.open(url);
  };

  return (
    <Carousel className="custom-slider" autoplay>
      {/* First slide */}
      <div className="carousel_item_45">
        <LazyLoadImage  className="slider_image_main"  src={img2} alt="Slide1" loading="lazy" />
      </div>

      {/* Second slide */}
      <div className="carousel_item_45">
        <div className="slid-img-logos container">
          <div className="slid-img1 row row-cols-3">
            {isImage.slice(0, 15).map((item, index) => (
              <LazyLoadImage
                key={index}
                src={item.image}
                alt={`Logo ${index}`}
                className="col"
                onClick={() => handleUrl(item.url)}
              />
            ))}
          </div>
        </div>
      </div>

      {/* Third slide */}
      <div className="carousel_item_45">
        <LazyLoadImage className="slider_image_main" src={img3} alt="Slide 3" />
      </div>
    </Carousel>
  );
};

export default ImageSlider;





