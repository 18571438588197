import React from 'react'
import { HashLoader } from 'react-spinners'

const Loader = () => {
  return (
    <>
      <div className="" style={{display:'flex',justifyContent:'center',alignItems:'center',height:'100vh',width:'100%'}}>
      <HashLoader color="#36d7b7" loading={true} size={70}/>
      </div>
    </>
  )
}

export default Loader;
