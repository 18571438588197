import { SET_CET, SET_COMPANY_NAME } from "./Constants";

const initialState = {
  cat: "",
  company: "",
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CET:
      return {
        ...state,
        cat: action.payload,
      };
    case SET_COMPANY_NAME:
      return {
        ...state,
        company: action.payload,
      };
    default:
      return state;
  }
};
