import React, { useEffect, useState } from "react";
import { AiOutlineTwitter } from "react-icons/ai";
import SendEnquiry from "../components/SendEnquiry";
import { Link } from "react-router-dom";
import { logo } from "../Assets/images/Image";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { BiLogoGmail, BiSolidPhone } from "react-icons/bi";
import axios from "axios";

const Footer = ({ position }) => {
  const [blog, setBlog] = useState([]);

  useEffect(() => {
    getDataApi();
  }, []);

  const getDataApi = async () => {
    try {
      const res = await axios.get(
        "https://pharmapcdfranchise.co.in/medicine_admin/api/allblogs"
      );
      //console.log(res.data, "hfhfgyhtgh");
      const rev = res.data.reverse();
      //console.log(rev,'data rev====s');
      setBlog(rev);
    } catch (error) { }
  };
  const handleMove = () => {
    window.scrollTo(0, position);
  };
  return (
    <>
      {/* Footer */}
      <div className="footer_bg_225">
        <footer className="text-center text-lg-start text-muted ">
          {/* Section: Social media */}

          {/* Section: Social media */}
          {/* Section: Links  */}

          <div className="container text-center text-md-start mt-5">
            {/* Grid row */}
            <div className="row mt-3">
              {/* Grid column */}
              <div className="col-md-12 col-lg-3 mx-auto mb-4">
                {/* Content */}
                <h6 className="text-uppercase fw-bold mb-4">
                  <i className="fas fa-gem me-3 text-secondary" />
                  <LazyLoadImage src={logo} className="footer-logo" alt="Footer_logo" />
                </h6>
                <p className=" fw-normal" style={{}}>
                  Pharma PCD franchise is an platform that connects pharma
                  corporations and vendors in India. It permits pharma
                  entrepreneurs to grant specific rights to their franchisees in
                  vacant areas. Pharma PCD franchise meets the developing want
                  for drugs and pharma products in India through offering the
                  top PCD pharma franchise and contract manufacturing
                  organizations in India. Our listed pharma PCD companies offer
                  pharma franchise opportunity during India for a huge spectrum
                  of excessive great pharmaceutical merchandise along with
                  General Range, Dermatology or Skincare Range, Ayurvedic Range,
                  Cardiology Diabetology Range, Gynaecology Range, Critical Care
                  Range, Antibiotics Range, Neurology or Neuropsychiatry Range,
                  Ophthalmology Range, Veterinary Range, Nutraceutical Range and
                  greater.
                </p>
              </div>
              {/* Grid column */}
              {/* Grid column */}
              <div className="col-md-12 col-lg-3 mx-auto mb-4 categories-foot-a-chng">
                {/* Links */}
                <h6 className="text-uppercase fw-bold mb-4">Categories</h6>
                {/* {
              company.map((item)=>(
                <p key={item.id}>
              <Link to= {item.url} className="text-reset"> {item.company}</Link> 
            </p>
              ))
            } */}
                <div className="row">
                  <p className="fw-normal">
                    {" "}
                    <Link
                      to="/PCD-Franchise-in-Gynae-Products"
                      className="fw-normal"
                      onClick={handleMove}
                    >
                      PCD Franchise In Gynae Products
                    </Link>
                  </p>
                  <p>
                    <Link
                      to="/PCD-Pharma-Franchise-in-Dermatology-Products"
                      onClick={handleMove}
                    >
                      PCD Pharma Franchise In Dermatology Products
                    </Link>
                  </p>
                  <p>
                    {" "}
                    <Link
                      to={"/PCD-Pharma-Franchise-in-Opthalmic-Range"}
                      onClick={handleMove}
                    >
                      PCD Pharma Franchise In Opthalmic Range
                    </Link>
                  </p>
                  <p>
                    {" "}
                    <Link
                      to={
                        "/PCD-Franchise-In-Hormonal%252FInfertility-Drugs"
                      }
                      onClick={handleMove}
                    >
                      PCD Franchise In Hormonal/Infertility Drugs
                    </Link>
                  </p>
                  <p>
                    {" "}
                    <Link
                      to={"/PCD-Pharma-in-Gynae-Speciality"}
                      onClick={handleMove}
                    >
                      PCD Pharma in Gynae Speciality
                    </Link>
                  </p>
                  <p>
                    {" "}
                    <Link
                      to="/PCD-Pharma-Franchise-in-Neuropsychiatric-Products"
                      onClick={handleMove}
                    >
                      PCD Pharma Franchise In Neuropsychiatric Products
                    </Link>
                  </p>
                </div>

                {/* <p>
              <Link to="/" className="text-reset"> Orange biotech</Link> 
               
              
            </p>
            <p>
            <Link to="/" className="text-reset"> Curasia Medilabs</Link> 
            </p>
            <p>
            <Link to="/" className="text-reset"> Biostem Pharma</Link> 
            </p>
            <p>
            <Link to="/" className="text-reset"> Bioversal remedies</Link> 
            </p> */}
              </div>
              {/* Grid column */}
              {/* Grid column */}
              <div className="col-md-12 col-lg-3 mx-auto mb-4">
                {/* Links */}
                <h6 className="text-uppercase fw-bold mb-4">Blog-pages</h6>
                {blog.slice(0,5).map((item) => (
                  <p key={item.id}>
                    <Link
                      to={`/blog`}
                      className="fw-normal"
                      onClick={handleMove}
                    >
                      {item.title}
                    </Link>
                  </p>
                ))}
              </div>
              {/* Grid column */}
              {/* Grid column */}
              <div className="col-md-12 col-lg-3 mx-auto mb-md-0 mb-4">
                {/* Links */}
                <h6 className="text-uppercase fw-bold mb-4">Contact Us</h6>
                {/* <p>
                    <i className="fas fa-home me-3 text-secondary" /> New York,
                    NY 10012, US
                  </p> */}
                <p className="fw-normal">
                  <i className="fas fa-envelope me-1 text-secondary" />
                  <span>
                    {" "}
                    <BiLogoGmail color="red" size={20} />{" "}
                  </span>{" "}
                  getpharmafranchise@gmail.com
                </p>
                <p className="fw-normal">
                  <i className="fas fa-phone me-1 text-secondary" />
                  <span>
                    <BiSolidPhone color="#2698EC" size={20} />
                  </span>
                  +91 90340 65534
                </p>
                <Link to={'/policy'} onClick={handleMove} className="fw-normal px-4 pt-3 text-dark">
                  Privacy Policy
                </Link>
                {/* <p>
                    <i className="fas fa-print me-3 text-secondary" /><span><BiSolidPhone/></span> + 01 234
                    567 89
                  </p> */}
              </div>
              {/* Grid column */}
            </div>
            {/* Grid row */}
          </div>

          {/* Section: Links  */}
          {/* Copyright */}
          <div
            className="text-center p-4"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.025)" }}
          >
            © 2023 Copyright:
            <Link to={"/"} className="text-reset fw-bold">
              Pharma pcd
            </Link>
          </div>
          {/* Copyright */}
        </footer>
      </div>
      {/* Footer */}
    </>
  );
};

export default Footer;
