import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { SetCompanyName } from "../Redux/Action";
import LoaderDiv from "../components/LoaderDiv";
import CategoriesList from "../Layouts/CategoriesList";
import { ImLocation2 } from "react-icons/im";
import { BiPhoneCall } from "react-icons/bi";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Seo from "../components/Seo";


const CategoriesPage = ({ position }) => {
  const [data, setData] = useState([]);
  // console.log(data, "cat data============");
  const [isUrlId, setUrlId] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isCompany, setIsCompany] = useState([]);
  const [show, setShow] = useState(true);
  const [categoryAll, setCategoryAll] = useState(false);
  const [dataLength, setDataLength] = useState();
  //console.log(dataLength, "data length========>");
  const [Loading, setLoading] = useState(true);
  useEffect(() => {
    window.scrollTo(0, position);
  }, [position]);
  const Params = useParams();
  const cat = Params?.cat;
  console.log(cat,"======================jdcataja")
  const catChange = cat
    .replace(/\(/g, "%28")
    .replace(/\)/g, "%29")
    .replace(/\//g, "%252F");

  // console.log(encodeURIComponent(encodeURIComponent(cat)),catChange,"jjj")

  const dispatch = useDispatch();
  //console.log(cat);

  // useEffect(() => {
  //   const pathSegments = window.location.pathname.split("/");
  //   const categoriesIndex = pathSegments.indexOf("categories");

  //   if (categoriesIndex !== -1 && categoriesIndex < pathSegments.length - 1) {
  //     // Extract the "categories" segment
  //     const categories = pathSegments[categoriesIndex + 1];
  //     console.log("Extracted categories:", categories);
  //     setUrlId(categories);
  //   }
  // }, []);

  useEffect(() => {
    if (cat) {
      getCategoriesData();
    }
  }, [cat]);

  const getCategoriesData = async () => {
    try {
      const res = await axios(
        `https://pharmapcdfranchise.co.in/medicine_admin/api/categorybycomp/${catChange}`
      );
      //console.log(res.data, "categories data=================");
      const shuffledData = [...res.data.company];
      //console.log(shuffledData,'-------------->');
      for (let i = shuffledData.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledData[i], shuffledData[j]] = [shuffledData[j], shuffledData[i]];
      }
      const shuffleDivdData = [...res.data.division];
      //console.log(shuffledData,'-------------->');
      for (let i = shuffleDivdData.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffleDivdData[i], shuffleDivdData[j]] = [
          shuffleDivdData[j],
          shuffleDivdData[i],
        ];
      }
      setIsLoading(false);
      setLoading(false);
      setData(shuffleDivdData);
      setIsCompany(shuffledData);
      const div = res.data.division;
      const com = res.data.company;
      // Check the length of the arrays
      const divLength = Array.isArray(div) ? div.length : 0;
      const comLength = Array.isArray(com) ? com.length : 0;

      // Calculate the combined length
      const combinedLength = divLength + comLength;

      setDataLength(combinedLength);
    } catch (error) {}
  };
  const handleCompanyName = (item) => {
    // console.log(item);
    dispatch(SetCompanyName(item));
  };
  if (isLoading) {
    return <LoaderDiv />;
  }
  const handleCategory = () => {
    setCategoryAll(true);
    setShow(false);
  };

  function convertToTitleCase(str) {
    return str
      .split('-') // Split the string by hyphens
      .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
      .join(' '); // Join them back with spaces
  }
  const slug = convertToTitleCase(catChange);
  return (
    <div className="cardparent">
      <Seo
        title={`${slug} | Pharma PCD Franchise - Call Now!`}
        description="Pharma PCD Franchise is the Top PCD Pharma Company in India.Get 60000+ Products in Pharma PCD Franchise from the list of the best 10 PCD Pharma franchise companies in India."
        type="webapp"
        name="Bioraiz Pharma"
      />
      <div className="cate-update">
        <div className="row">
          <CategoriesList handleCategory={handleCategory} show={show} />
          <div className="col-9 cate-head-respons ps-0">
            <div className="container">
              <div className="row justify-content-center">
                {dataLength === 1 ? (
                  <>
                    {data.map((item, index) => (
                      <div className="company_bg-detail-55" key={index}>
                        <div className="container mb-5">
                          <div className="row">
                            <div className="col-lg-6 col-md-12 col-sm-12 my-3">
                              <LazyLoadImage
                                className="details_logo_1 img"
                                src={item?.image}
                                alt="img"
                              />
                            </div>

                            <div className="col-lg-6 col-md-12 col-sm-12 my-4 ps-4">
                              <h1 className="company-head_main_abc">
                                {item?.company}
                              </h1>

                              {/* <p className="something-about-company">
                                  {" "}
                                  {item?.description_front}{" "}
                                </p> */}
                              <div
                                className="something-about-company"
                                dangerouslySetInnerHTML={{
                                  __html: item?.description_front,
                                }}
                              />

                              <div
                                className=""
                                style={{
                                  display: "flex",
                                  fontSize: 15,
                                  fontWeight: "500",
                                  color: "#000000",
                                }}
                              >
                                <ImLocation2 color="#0f0b59" size={20} />

                                <div
                                  className="st-post-text mt-1"
                                  dangerouslySetInnerHTML={{
                                    __html: item?.address,
                                  }}
                                />
                              </div>

                              <p className="address_comp_1">
                                <span>
                                  <BiPhoneCall />
                                </span>{" "}
                                {item?.contact}
                              </p>

                              <Link
                                to={item?.url}
                                target="_blank"
                                className="comp-detail_54"
                              >
                                Profile
                              </Link>

                              <button
                                type="button"
                                className="comp-detail_54"
                                onClick={() => handleCompanyName(item?.company)}
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModals"
                              >
                                Send Enquiry
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <>
                    {Loading ? (
                      <LoaderDiv />
                    ) : (
                      <>
                        {isCompany.length > 0 ? (
                          isCompany?.map((item, index) => {
                            return (
                              <div
                                className="col-lg-4  col-md-6 mt-4 mb-4"
                                key={index}
                              >
                                <div className="row">
                                  <div className="col-lg-12 col-md-12">
                                    <div className="features-product-content">
                                      <div className="main-img">
                                        <LazyLoadImage
                                          src={item.image}
                                          alt="img"
                                        />
                                      </div>
                                      <h2 className="title">
                                        {" "}
                                        <span>{item.title}</span>
                                      </h2>

                                      {/* <p className="card_content_2">
                                  {item.description_front.slice(0, 45)}
                                  <span>...</span>
                                </p> */}

                                      <div className="features-product-bottom">
                                        {/* <Link to={item.url}>
                                              <button
                                                type="button"
                                                className="st-btn st-style1 st-size-large st-color1"
                                              >
                                                Profile
                                              </button>
                                            </Link> */}
                                        <Link
                                          to={item.url}
                                          // to={`/divisions/${item.slug}`}
                                          type="button"
                                          className="st-btn st-style1 st-size-large st-color1"
                                        >
                                          Details
                                        </Link>
                                        <button
                                          onClick={() =>
                                            handleCompanyName(item.title)
                                          }
                                          type="button"
                                          className="st-btn st-style1 st-size-large st-color1"
                                          data-bs-toggle="modal"
                                          data-bs-target="#exampleModals"
                                        >
                                          Enquiry
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <h1 className=" text-center py-5 text-danger">
                            Data not found
                          </h1>
                        )}
                      </>
                    )}

                    {data.length > 0 ? (
                      data?.map((item, index) => {
                        return (
                          <div
                            className="col-lg-4  col-md-6 mt-4 mb-4"
                            key={index}
                          >
                            <div className="row">
                              <div className="col-lg-12 col-md-12">
                                <div className="features-product-content">
                                  <div className="main-img">
                                    <LazyLoadImage src={item.image} alt="img" />
                                  </div>
                                  <h2 className="title">
                                    {" "}
                                    <span>{item.title}</span>
                                  </h2>

                                  {/* <p className="card_content_2">
                                  {item.description_front.slice(0, 45)}
                                  <span>...</span>
                                </p> */}

                                  <div className="features-product-bottom">
                                    {/* <Link to={item.url}>
                                        <button
                                          type="button"
                                          className="st-btn st-style1 st-size-large st-color1"
                                        >
                                          Profile
                                        </button>
                                      </Link> */}
                                    <Link
                                      // to={`/divisions-details/${item.title_slug}`}
                                      to={item.url}
                                      type="button"
                                      className="st-btn st-style1 st-size-large st-color1"
                                    >
                                      Details
                                    </Link>
                                    <button
                                      onClick={() =>
                                        handleCompanyName(item.title)
                                      }
                                      type="button"
                                      className="st-btn st-style1 st-size-large st-color1"
                                      data-bs-toggle="modal"
                                      data-bs-target="#exampleModals"
                                    >
                                      Enquiry
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoriesPage;
