import axios from "axios";
import React, { useState, useEffect } from "react";

const RowData = () => {
  const [data, setData] = useState([]);
  const [showMore, setShowMore] = useState(false); // State to track whether to show more data

  useEffect(() => {
    getAPI();
  }, []);

  const getAPI = async () => {
    try {
      const response = await axios.get(
        "https://pharmapcdfranchise.co.in/medicine_admin/api/categorybyidss/"
      );
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const columns = [];
  const itemsToShow = showMore ? data : data.slice(0, 5); // Decide whether to show all data or just the first 5

  for (let i = 0; i < itemsToShow.length; i += 7) {
    const column = itemsToShow.slice(i, i + 7);
    columns.push(column);
  }

  return (
    <div>
      <div style={{ display: "flex" }}>
        {columns.map((column, columnIndex) => (
          <div
            key={columnIndex}
            className="data-column"
            style={{
              display: "flex",
              flexDirection: "column",
              marginRight: "20px",
            }}
          >
            {column.map((item, itemIndex) => (
              <div
                key={itemIndex}
                className="data-item"
                style={{
                  border: "1px solid #ccc",
                  padding: "10px",
                  marginBottom: "10px",
                }}
              >
                {item.category}
              </div>
            ))}
          </div>
        ))}
      </div>
      {!showMore && (
        <button onClick={() => setShowMore(true)}>Show More</button>
      )}
    </div>
  );
};

export default RowData;
